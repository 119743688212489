import { Auth, Hub } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import './App.css';
const initialFormState = {
  username: '', password: '', email: '', code: '', formType: 'signIn'
}
function App() {
  const [formState, updateFromState] = useState(initialFormState)
  const [user, updateUser] = useState(null)
  useEffect(() => {
    checkUser()
    setAuthListener()
  }, [])
  async function setAuthListener() {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signOut':
          console.log(data)
          updateFromState(() => ({ ...formState, formType: 'signIn' }))
          break
        default:
          break
      }
    })
  }
  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      updateUser(user)
      updateFromState(() => ({ ...formState, formType: 'signedIn' }))
    } catch (err) {
      console.log(user)
    }
  }
  function onChange(event) {
    event.persist()
    updateFromState(() => ({ ...formState, [event.target.name]: event.target.value }))
  }
  const { formType } = formState
  function showSignUp() {
    const { username, code } = formState
    updateFromState(() => ({ ...formState, formType: 'signUp' }))
  }
  function showSignIn() {
    const { username, code } = formState
    updateFromState(() => ({ ...formState, formType: 'signIn' }))
  }
  async function signUp() {
    const { username, password, email } = formState
    await Auth.signUp({ username, password, attributes: { email } })
    updateFromState(() => ({ ...formState, formType: 'confirmSignUp' }))
  }
  async function confirmSignUp() {
    try {
      const { username, code } = formState
      await Auth.confirmSignUp(username, code)
      updateFromState(() => ({ ...formState, formType: 'signIn' }))
    }
    catch {
      console.log(formState)
    }
  }
  async function signIn() {
    const { username, password } = formState
    await Auth.signIn(username, password)
    updateFromState(() => ({ ...formState, formType: 'signedIn' }))
  }
  return (
    <div className="App">
      {
        formType === 'signUp' && (
          <div>
            <input name="username" onChange={onChange} placeholder="User Name" />
            <input name="password" type="password" onChange={onChange} placeholder="Password" />
            <input name="email" onChange={onChange} placeholder="Email" />
            <button onClick={signUp}>Sign Up</button>
            <button onClick={showSignIn}>Already have account?</button>
          </div>
        )
      }
      {
        formType === 'confirmSignUp' && (
          <div>
            <input name="code" onChange={onChange} placeholder="Confirmation Code" />
            <button onClick={confirmSignUp}>Confirm</button>
          </div>
        )
      }
      {
        formType === 'signIn' && (
          <div>
            <input name="username" onChange={onChange} placeholder="User Name" />
            <input name="password" type="password" onChange={onChange} placeholder="Password" />
            <button onClick={signIn}>Sign In</button>
            <button onClick={showSignUp}>Sign Up</button>
            <button onClick={() => Auth.federatedSignIn({provider:"Facebook"})}>Sign In With Facebook</button>
          </div>
        )
      }
      {
        formType === 'signedIn' && (
          <div>
            <h1>Welcome User</h1>
            <button onClick={() => Auth.signOut()}>Sign Out</button>
          </div>
        )

      }
    </div>

  );
}

export default App;
