/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:321f83e1-cef9-4710-b725-72ef1e017ed6",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_sBFE9VQxg",
    "aws_user_pools_web_client_id": "5dif94cpvlsqe0hajt0sjvoi1h",
    "oauth": {
        "domain": "ngafe-staging.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://ngafe.arifinbrothers.com/",
        "redirectSignOut": "https://ngafe.arifinbrothers.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
